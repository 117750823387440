const TokenAddress = "0xC739f3d38Debc182633F64873FF25f8Be03Fcfd0";
const ProfitPortalAddress = "0x92750347Cf3C029667243A7b2A05D4a2B84a4801";
const LotteryAddress = "0xAB727cA09EC95cF63EEE26aaf42B9b538ACd9b95";
const PancakeSwapRouterAddress = "0x10ED43C718714eb63d5aA57B78B54704E256024E";

module.exports = {
    TokenAddress,
    ProfitPortalAddress,
    LotteryAddress,
    PancakeSwapRouterAddress
};
