import React, { useState } from 'react';
import { Link } from 'react-router-dom'
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <header>
      <div className='leftH'>
        <img src={`${process.env.PUBLIC_URL}/logo192.webp`} alt='logo' className='logo' />
        <div className={`nav-links ${isOpen ? 'open' : ''}`}>
          <Link to='/Lottery' className='link' onClick={closeMenu}>
            <div className='headerItem'>Lottery</div>
          </Link>
          <Link to='/Swap' className='link' onClick={closeMenu}>
            <div className='headerItem'>Referral Buy</div>
          </Link>
          <Link to='/Burn' className='link' onClick={closeMenu}>
            <div className='headerItem'>OTC Sell & Burn</div>
          </Link>
          <a href='https://docs.crashcoin.fun' className='link' onClick={closeMenu} target='_blank' rel='noopener noreferrer'>
            <div className='headerItem'>Docs</div>
          </a>
        </div>
      </div>
      <div className='rightH'>
        <w3m-button />
        <div className='menu-icon' onClick={toggleMenu}>
          &#9776; {/* This is a hamburger icon */}
        </div>
      </div>
    </header>
  );
};

export default Header