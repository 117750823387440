import tokenList from '../tokenList.json';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react';
import "../App.css";
import { BrowserProvider, Contract, formatUnits, MaxUint256, ethers } from 'ethers';
import React, { useState, useEffect, useCallback } from 'react';
import { TokenAddress, LotteryAddress } from '../config';
import { Input, Popover, Radio, Modal, message } from 'antd';
import { Helmet } from 'react-helmet';

const LotteryABI = require('../abi/Lottery.json').abi;
const tokenABI = require('../abi/IBEP20.json').abi;

function Lottery(props) {
    const { address, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const [messageApi, contextHolder] = message.useMessage();
    const [isActive, setIsActive] = useState(null);
    const [entryAmount, setEntryAmount] = useState(null);
    const [tokensNeeded, setTokensNeeded] = useState(null);
    const [lotteryId, setLotteryId] = useState(null);
    const [allowance, setAllowance] = useState(null);
    const [entries, setEntries] = useState(null);
    const [participants, setParticipants] = useState([]);
    const [winners, setWinners] = useState([]);
    const [totalBurned, setTotalBurned] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [txDetails, setTxDetails] = useState({
        to: null,
        data: null,
        value: null,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [numberOfEntries, setNumberOfEntries] = useState(1);
    const [totalTokens, setTotalTokens] = useState(0);

    const fetchData = useCallback(async (signer) => {
        setRefresh(false);
        const LotteryContract = new Contract(LotteryAddress, LotteryABI, signer);
        const TokenContract = new Contract(TokenAddress, tokenABI, signer);

        const [lotteryActive, entryAmt, tokens, lotteryId, totalBurned, totalTokens] = await Promise.all([
            LotteryContract.lotteryActive(),
            LotteryContract.entryAmount(),
            LotteryContract.minimumTokens(),
            LotteryContract.lottery_id(),
            LotteryContract.totalBurned(),
            LotteryContract.totalTokens()
        ]);

        setIsActive(lotteryActive);
        setEntryAmount(formatUnits(entryAmt, 9).toString());
        setTokensNeeded(formatUnits(tokens, 9).toString());
        setLotteryId(lotteryId.toString());

        const signerEntries = await LotteryContract.lotteryEntries(lotteryId.toString(), address);
        setEntries(signerEntries.toString());

        const signerAllowance = await TokenContract.allowance(address, LotteryAddress);
        setAllowance(formatUnits(signerAllowance, 9).toString());

        const participantsList = await LotteryContract.getParticipants();
        setParticipants(participantsList);
        const winner0 = await LotteryContract.winners(0);
        const winner1 = await LotteryContract.winners(1);
        const winner2 = await LotteryContract.winners(2);
        const zeroAddress = '0x' + '0'.repeat(40);
        let winnersList = [];
        let winner0winnings, winner1winnings, winner2winnings;
        winner0winnings = "0";
        winner1winnings = "0";
        winner2winnings = "0";
        if (winner0 !== zeroAddress && winner1 !== zeroAddress && winner2 !== zeroAddress) {
            winnersList = [winner0, winner1, winner2];
            winner0winnings = await LotteryContract.lotteryWinners(0);
            winner1winnings = await LotteryContract.lotteryWinners(1);
            winner2winnings = await LotteryContract.lotteryWinners(2);
        }
        setWinners(winnersList.map((winner, index) => ({
            address: winner,
            winnings: [winner0winnings, winner1winnings, winner2winnings][index]
        })));
        setTotalBurned(formatUnits(totalBurned, 9).toString());

        setTotalTokens(formatUnits(totalTokens, 9).toString());
    }, [address]);

    useEffect(() => {
        const setupProvider = async () => {
            if (isConnected) {
                const ethersProvider = new BrowserProvider(walletProvider);
                const signer = await ethersProvider.getSigner();
                fetchData(signer);

                if (window.ethereum && window.ethereum.on) {
                    const handleAccountsChanged = (accounts) => {
                        if (accounts.length > 0) {
                            fetchData(ethersProvider.getSigner());
                        }
                    };
                    window.ethereum.on('accountsChanged', handleAccountsChanged);

                    return () => {
                        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
                    };
                }
            }
        };

        setupProvider();
    }, [isConnected, walletProvider, fetchData, isActive, refresh]);

    const handleApprove = async () => {
        if (isConnected) {
            const ethersProvider = new BrowserProvider(walletProvider);
            const signer = await ethersProvider.getSigner();
            const TokenContract = new Contract(TokenAddress, tokenABI, signer);
            try {
                const tx = await TokenContract.approve(LotteryAddress, MaxUint256);
                setIsLoading(true);
                await tx.wait();
                setIsLoading(false);
                setIsSuccess(true);
                messageApi.success('Approval successful');
            } catch (error) {
                setIsLoading(false);
                setIsSuccess(false);
                messageApi.error('Approval failed');
                console.error('Approval failed', error);
            }
            const newAllowance = await TokenContract.allowance(address, LotteryAddress);
            setAllowance(formatUnits(newAllowance, 9).toString());
        }
    };

    const handleParticipate = async (numberOfEntries) => {
        if (isConnected) {
            const ethersProvider = new BrowserProvider(walletProvider);
            const signer = await ethersProvider.getSigner();
            const LotteryContract = new Contract(LotteryAddress, LotteryABI, signer);
            try {
                const tx = await LotteryContract.participate(parseInt(numberOfEntries), { gasLimit: 500000 });
                setIsLoading(true);
                await tx.wait();
                setIsLoading(false);
                setIsSuccess(true);
                messageApi.success('Participate successful');
            } catch (error) {
                setIsLoading(false);
                setIsSuccess(false);
                messageApi.error('Participate failed');
                console.error('Participate failed', error.toString());
            }
            setRefresh(true);
        }
    };

    const sendTransaction = async (tx) => {
        try {
            setIsLoading(true);
            await tx.wait();
            setIsSuccess(true);
        } catch (error) {
            setIsSuccess(false);
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (isLoading) {
            messageApi.open({
                content: 'Waiting for transaction to be mined',
                type: 'loading',
                duration: 0,
            });
        } else {
            messageApi.destroy();
        }
    }, [isLoading]);

    useEffect(() => {
        if (isSuccess) {
            messageApi.open({
                type: 'success',
                content: 'Transaction Success',
                duration: 2,
            });
        } else if (txDetails.to) {
            messageApi.open({
                type: 'error',
                content: 'Transaction Failed',
                duration: 2,
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (txDetails.to && isConnected) {
            sendTransaction(txDetails);
        }
    }, [txDetails]);

    const handleIncrement = () => {
        const max = (Number(tokensNeeded) - Number(totalTokens)) / Number(entryAmount);
        if (numberOfEntries + 1 > max)
            return
        setNumberOfEntries(prevEntries => prevEntries + 1);
    };

    const handleDecrement = () => {
        setNumberOfEntries(prevEntries => Math.max(1, prevEntries - 1));
    };

    if (!isConnected) {
        return <p>Please connect your wallet</p>;
    }

    const title = 'CrashCoin Lottery';
    const description = 'Participate in the CrashCoin lottery for a chance to win big!';
    const imageUrl = `${process.env.PUBLIC_URL}/logo192.webp`;
    const url = 'https://app.crashcoin.fun/Lottery';

    return (
        <div>
            <div>
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={description} />
                    <meta property="og:image" content={imageUrl} />
                    <meta property="og:url" content={url} />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={description} />
                    <meta name="twitter:image" content={imageUrl} />
                </Helmet>
                {/* <h2>{title}</h2>
                <p>{description}</p>
                <img src={imageUrl} alt={title} /> */}
            </div>
            {contextHolder}
            {isActive != null && entryAmount !== null && tokensNeeded !== null &&
                <div className="card">
                    <div className={`status ${isActive ? 'active' : 'inactive'}`}>
                        {isActive ? `Lottery #${lotteryId} is active` : `Lottery #${lotteryId - 1} is finished`}
                    </div>
                    <div className="total-burned">
                        Total Burned: {totalBurned} $CRASH
                    </div>
                    {isActive &&
                        <div className="entry-amount">
                            Entry Amount: {entryAmount} $CRASH
                        </div>}
                    {isActive && <div className="tokens-needed">
                        Draw on: {tokensNeeded} $CRASH
                    </div>
                    }
                </div>
            }
            {isActive && allowance !== null && entries !== null &&
                <div className="card">
                    <div className="entry-amount" style={{ marginBottom: '10px' }}>
                        Your entries: {entries} <br />
                        remaining entries: {((Number(tokensNeeded) - Number(totalTokens)) / Number(entryAmount)).toFixed(0)}
                    </div>
                    Number of entries:
                    <div className="entry-controls">
                        <button className="entry-button" onClick={handleDecrement}>-</button>
                        <span className="entry-count">{numberOfEntries}</span>
                        <button className="entry-button" onClick={handleIncrement}>+</button>
                    </div>
                    {allowance > entryAmount * numberOfEntries ? (
                        <div className="Button" onClick={() => handleParticipate(numberOfEntries)}>
                            Participate
                        </div>
                    ) : (
                        <div className="Button" onClick={handleApprove}>
                            Enable
                        </div>
                    )}
                </div>
            }
            <div className="card">
                <h2>Lottery #{parseInt(lotteryId) > 0 ? parseInt(lotteryId) - 1 : 0} Winners</h2>
                {winners.length > 0 ? (
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                        {winners.map((winner, index) => (
                            <li key={index} style={{ fontSize: '0.8em' }}>
                                Winner {index + 1} ({formatUnits(winner.winnings, 9)} $CRASH) <br></br> {winner.address}
                            </li>
                        ))}
                        <br></br>
                        <li style={{ fontSize: '0.8em' }}>
                            Burned: 20%
                        </li>
                    </ul>
                ) : (
                    <p>No winners yet</p>
                )}
            </div>
            {
                isActive &&
                <div className="card">
                    <h2>Participants</h2>
                    {participants.length > 0 ? (
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                            {participants.map((participant, index) => (
                                <li key={index} style={{ fontSize: '0.8em' }}>{participant}</li>
                            ))}
                        </ul>
                    ) : (
                        <p>No participants yet</p>
                    )}
                </div>
            }
        </div >
    );
}

export default Lottery;
